$peach-puzz: #febd99;
$purple: #b0a8fe;
$yellow: #def080;
$black: #141414;

$navbar-height: 7vh;
$footer-height: 2rem;

:root {
  --pico-muted-border-color: #ffffff44 !important;
}

@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-weight: 400;
  font-style: normal;
}

html {
  font-family: "Pretendard-Regular", sans-serif;
  background-color: #0a0a0a;
}

.purple {
  color: $purple;
}

.peach-puzz {
  color: $peach-puzz;
}

.black {
  color: $black;
}

.yellow {
  color: $yellow;
}

.white {
  color: #ffffff;
}

span {
  color: #c2c7d0;
}

ul,
ol {
  margin-left: 2rem;
}

.hidden {
  display: none;
}

.visible {
  display: block;
  width: 0px;
}

.vertical {
  display: flex;
  flex-direction: column;
}

.vertical-item {
  padding-bottom: 1rem;
}

.vertical-item:last-child {
  padding-bottom: 0;
}

body {
  background-color: #0a0a0a;
  font-weight: 400;
}

.border-bottom {
  border-bottom: 1px $peach-puzz solid;
}

.swiper {
  width: 100%;
  height: 100%;
  padding-bottom: 3rem !important;
  z-index: 0 !important;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  color: white;
  background-color: $black;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-scrollbar {
  background: #555555 !important;
}

.swiper-scrollbar-drag {
  background: $purple !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

// mobile
@media only screen and (min-width: 360px) {
  body {
    font-size: 12px;
  }
  h2 {
    font-size: 16px;
    font-weight: 600;
  }

  .finale-img {
    padding: 0 1rem;
  }
}

.nav-bar {
  padding: 0 1rem;
  height: $navbar-height;

  display: flex;
  justify-content: space-between;

  border-bottom: 1px $peach-puzz solid;
}

.nav-logo {
  display: flex;
  width: 100px;
  cursor: pointer;
}

.menus {
  width: 100%;
  position: absolute;
  top: $navbar-height;
  left: 0;
  height: 100%;
  z-index: 10000;
  background-color: $black;
  color: $peach-puzz;
  text-align: center;

  & > .menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 20px;

    & > span {
      color: $peach-puzz;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 20px;
      height: 6vh;
      border-bottom: 1px $peach-puzz solid;

      &:hover {
        color: $purple;
      }
    }
    & > .sub-menu {
      position: relative;
      width: 100%;
      background-color: $black;
      border: 1px $peach-puzz solid;

      & > .sub-menu-item {
        color: $peach-puzz;
        font-size: 14px;
        padding-top: 1rem;

        &:last-child {
          padding-bottom: 1rem;
        }

        &:hover {
          color: $purple;
        }
      }
    }
    & > span.separator {
      display: none;
      height: 1rem;
      border-left: 1px solid;
      margin: 0 0.5rem;
    }
  }
}

.cm-page {
  margin: 2vh 5vw;
  padding: 0 1vw;
  min-height: 50vh;
}

.mobile-logo {
  width: 84px;
}
.mobile-slogan {
  width: 300px;
}
.welcome-text {
  font-size: large;
  white-space: nowrap;
}

.small-text {
  font-size: 12px;
  font-weight: 600;
  font-style: italic;
}

.medium-text {
  font-size: 16px;
  font-weight: 600;
}

.big-text {
  font-size: 30px;
  font-weight: 800;
}

.welcome-section {
  border-radius: 16px;
  background-color: $black;
  padding: 2rem 4rem;
  align-items: center;
  width: 100%;
}

.footer-about-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: $peach-puzz;

  & > section {
    width: 100%;
  }

  & > section.left {
    width: 50%;
    order: 2;

    & > table {
      border: 1px;
      td {
        background-color: #0a0a0a !important;
      }
      tr > td:nth-of-type(2) {
        padding-left: 1.5rem;
      }
    }
  }

  & > section.center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    margin-bottom: $footer-height;
    order: 1;

    & > .slogan {
      font-weight: 600;
    }

    & > div + div {
      margin-top: 1vh;
    }

    & > div a {
      text-decoration: none;
      color: $purple;
    }
  }
}

.footer-fixed-section {
  z-index: 10000;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: $footer-height;
  padding: 0 0.5rem;

  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $peach-puzz;
  background-color: $black;

  & > .left {
    font-weight: 800;
    margin: 0;
  }

  & > .right {
    margin: 0;

    display: flex;
    align-items: center;
    gap: 0.5rem;

    & > a {
      line-height: 0;
      svg {
        width: 1rem;
        fill: $peach-puzz;
      }
    }
  }
}

// mobile
@media only screen and (max-width: 809px) {
  body {
    font-size: 12px;
  }
  h2 {
    font-size: 16px;
    font-weight: 600;
  }

  div.hamburger-react {
    cursor: pointer;
    height: 48px;
    position: relative;
    transition: all 0.4s cubic-bezier(0, 0, 0, 1) 0s;
    user-select: none;
    width: 48px;
    outline: none;
    transform: none;
    background-color: rgba(0, 0, 0, 0);
    color: $peach-puzz;
    border: none;
  }

  .mobile-logo {
    width: 84px;
  }
  .mobile-slogan {
    width: 300px;
  }
  .welcome-text {
    font-size: large;
    white-space: nowrap;
  }

  .small-text {
    font-size: 12px;
    font-weight: 600;
    font-style: italic;
  }

  .big-text {
    font-size: 30px;
    font-weight: 800;
  }

  .welcome-section {
    border-radius: 16px;
    background-color: $black;
    padding: 2rem 4rem;
    align-items: center;
    width: 100%;
  }
}

// Desktop
@media only screen and (min-width: 810px) {
  body {
    font-size: 20px;
  }

  h2 {
    font-size: 24px;
    font-weight: 600;
  }

  li > a,
  span > a {
    color: $purple;
  }

  .menus {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-weight: 800;
    position: initial;

    & > .menu-item {
      border: none;
      flex-direction: row;

      & > span {
        border-bottom: none;
        width: unset;
      }

      & > span.separator {
        display: block;
      }

      &:last-of-type > span.separator {
        display: none;
      }

      &:hover {
        & > .sub-menu {
          position: absolute;
          width: max-content;
          transform: translateX(-0.5rem);
        }
      }

      &:last-of-type:hover {
        & > .sub-menu {
          transform: none;
        }
      }

      & > .sub-menu {
        background-color: $black;
        border: 1px $peach-puzz solid;
        color: $peach-puzz;

        & > .sub-menu-item {
          cursor: pointer;
          color: $peach-puzz;

          &:hover {
            color: $purple;
          }
        }
      }
    }
  }

  .slide-open {
    visibility: hidden;
  }

  .mobile-logo {
    width: 181px;
  }

  .mobile-slogan {
    width: 510px;
  }

  .welcome-text {
    font-size: x-large;
    white-space: nowrap;
  }

  .small-text {
    font-size: 16px;
    font-weight: 600;
    font-style: italic;
  }

  .big-text {
    font-size: 40px;
    font-weight: 800;
  }

  .welcome-section {
    border-radius: 16px;
    background-color: $black;
    padding: 2rem 10rem;
    align-items: center;
    width: 100%;
  }
}
