@use "@picocss/pico/scss/pico" with (
  $theme-color: "violet"
);

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;

  -webkit-user-drag: none;
  /* Safari */
  -khtml-user-drag: none;
  /* Konqueror HTML */
  -moz-user-drag: none;
  /* Old versions of Firefox */
  -o-user-drag: none;
  /* Old versions of Opera */
  user-drag: none;
  /* Non-prefixed version */
}

body,
html,
#root {
  overscroll-behavior: none;

  min-height: 100svh;
  max-height: 100svh;
  height: 100svh;

  max-width: 100vw;

  word-break: keep-all;
  overflow-wrap: break-all;

  background-color: #090909;
}

body {
  margin: 0;
  padding: 0;

  overflow: hidden auto;

  font-family: Pretendard,
  -apple-system,
  BlinkMacSystemFont,
  Segoe UI,
  Roboto,
  Oxygen,
  Ubuntu,
  Cantarell,
  Fira Sans,
  Droid Sans,
  Helvetica Neue,
  sans-serif;
  font-weight: 400;
  color: #141414;

  scroll-behavior: smooth;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;

  -webkit-box-sizing: border-box !important;
  -moz-box-sizing: border-box !important;
  -ms-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

* {
  outline: none !important;
}

a {
  color: inherit;
  text-decoration: none;
}
